import $ from 'jquery'
import '../sass/main.scss';
import menu from './menu';
import lazyLoad from './lazyLoad';
// import smoothState from './smoothState';

// window.$ = require('jquery');
// window.jQuery = require('jquery');

const siteGlobal = {
  initReady() {
    // smoothState.initReady();
  },
  initLoad() {
  	lazyLoad.initLoad();
    menu.initLoad();
    this.popupInit();
    this.messagePopupInit();
  },
  initScroll( ){
  },
  initResize() {
  },
  popupInit: function(){
    $('.numSer__popTrig').on('click', function(){
      $('#pop').fadeToggle(250);
    })
  },
  messagePopupInit: function(){
    $('#down-sheet, #down-brochure, .brPop__closeBtn, .brPop__close').on('click', function(){
      $('.brPop').fadeToggle(250);
    })
  },
  rebuildAllEvents() {
    siteGlobal.initLoad();
  },
};

$(document).ready(() => {
  siteGlobal.initReady();
});

$(document).on('rebuild', () => {
  siteGlobal.rebuildAllEvents();
});

$(window).on('load', () => {
  $('#preloader').addClass('loaded');
  siteGlobal.initLoad();
});

$(window).on('resize', () => {
  siteGlobal.initResize();
});

$(window).on('scroll', () => {
  siteGlobal.initScroll();
});