const menu = {
	initLoad() {
		this.menuInit();
	},

	menuInit() {
    	$('.header__hamburger').on('click',() => {
      		$('.header__hamburger, .header').toggleClass('opened');
    	})
  	},

}

module.exports = menu;